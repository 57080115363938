import React from "react";
import { useOverrides, Override } from "@quarkly/components";
import { Text, Box, Image, Section } from "@quarkly/widgets";
const defaultProps = {
	"padding": "80px 0 80px 0"
};
const overrides = {
	"box": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"align-items": "center",
			"flex-direction": "column",
			"justify-content": "center",
			"margin": "0px 0px 56px 0px",
			"width": "100%",
			"sm-margin": "0px 0px 30px 0px"
		}
	},
	"text": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 16px 0px",
			"color": "--dark",
			"text-align": "center",
			"sm-font": "normal 700 42px/1.2 \"Source Sans Pro\", sans-serif",
			"children": <>
				TESTIMONIALS{"\n\n"}
			</>,
			"font": "--headline2"
		}
	},
	"text1": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"color": "--grey",
			"text-align": "center",
			"font": "--lead",
			"children": <>
				Dive into the experiences of our esteemed patrons who've embarked on the Deutsche Sportwelt Pub journey. Their words paint a vivid picture of our dedication to blending sport, culture, and culinary artistry, providing a unique ambiance that leaves an indelible mark on all who visit.{"\n\n"}
			</>
		}
	},
	"box1": {
		"kind": "Box",
		"props": {
			"display": "grid",
			"grid-template-columns": "repeat(3, 1fr)",
			"grid-gap": "16px",
			"lg-grid-template-columns": "repeat(2, 1fr)",
			"md-grid-template-columns": "1fr"
		}
	},
	"box2": {
		"kind": "Box",
		"props": {
			"padding": "50px 55px 50px 55px",
			"sm-padding": "55px 40px 50px 55px",
			"border-width": "1px",
			"border-style": "solid",
			"border-radius": "24px",
			"border-color": "--color-lightD2",
			"display": "flex",
			"flex-direction": "column",
			"align-items": "flex-start"
		}
	},
	"image": {
		"kind": "Image",
		"props": {
			"src": "https://uploads.quarkly.io/612695d67f2b1f001fa06c1f/images/favourite-31.png?v=2021-08-30T20:05:47.446Z",
			"margin": "0px 0px 33px 0px"
		}
	},
	"text2": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 35px 0px",
			"color": "--dark",
			"font": "--lead",
			"lg-margin": "0px 0px 50px 0px",
			"sm-margin": "0px 0px 30px 0px",
			"flex": "1 0 auto",
			"children": <>
				The fusion of sports and German culinary artistry at Deutsche Sportwelt Pub is pure genius! From the Gymnasium Gold lager to the Marathoner's Schnitzel, every bite and sip took me on a journey. Can't wait for my next visit!{"\n\n"}
			</>
		}
	},
	"box3": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"margin": "0px 17px 0px 0px",
			"align-items": "flex-start",
			"flex-direction": "column"
		}
	},
	"image1": {
		"kind": "Image",
		"props": {
			"width": "43px",
			"height": "43px",
			"src": "https://uploads.quarkly.io/612695d67f2b1f001fa06c1f/images/image4.png?v=2021-08-25T19:47:08.343Z",
			"border-radius": "50зч",
			"margin": "0px 15px 12px 0px"
		}
	},
	"box4": {
		"kind": "Box",
		"props": {}
	},
	"text3": {
		"kind": "Text",
		"props": {
			"color": "--dark",
			"font": "normal 600 16px/1.5 --fontFamily-googleSourceSansPro",
			"margin": "0px 0px 2px 0px",
			"children": <>
				Sophie T.{"\n\n"}
			</>
		}
	},
	"box5": {
		"kind": "Box",
		"props": {
			"padding": "50px 55px 50px 55px",
			"sm-padding": "55px 40px 50px 55px",
			"border-width": "1px",
			"border-style": "solid",
			"border-radius": "24px",
			"border-color": "--color-lightD2",
			"display": "flex",
			"flex-direction": "column",
			"align-items": "flex-start"
		}
	},
	"image2": {
		"kind": "Image",
		"props": {
			"src": "https://uploads.quarkly.io/612695d67f2b1f001fa06c1f/images/favourite-31.png?v=2021-08-30T20:05:47.446Z",
			"margin": "0px 0px 33px 0px"
		}
	},
	"text4": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 35px 0px",
			"color": "--dark",
			"font": "--lead",
			"lg-margin": "0px 0px 50px 0px",
			"sm-margin": "0px 0px 30px 0px",
			"flex": "1 0 auto",
			"children": <>
				What a remarkable experience! The BierGarten Library was a highlight - reading about legendary moments while enjoying a crisp ale was truly unique. Deutsche Sportwelt Pub has become my go-to place for evenings out.{"\n\n"}
			</>
		}
	},
	"box6": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"margin": "0px 17px 0px 0px",
			"align-items": "flex-start",
			"flex-direction": "column"
		}
	},
	"image3": {
		"kind": "Image",
		"props": {
			"width": "43px",
			"height": "43px",
			"src": "https://uploads.quarkly.io/612695d67f2b1f001fa06c1f/images/image6.png?v=2021-08-25T19:47:23.498Z",
			"border-radius": "50зч",
			"margin": "0px 15px 12px 0px"
		}
	},
	"box7": {
		"kind": "Box",
		"props": {}
	},
	"text5": {
		"kind": "Text",
		"props": {
			"color": "--dark",
			"font": "normal 600 16px/1.5 --fontFamily-googleSourceSansPro",
			"margin": "0px 0px 2px 0px",
			"children": <>
				Liam F.{"\n\n"}
			</>
		}
	},
	"box8": {
		"kind": "Box",
		"props": {
			"padding": "50px 55px 50px 55px",
			"sm-padding": "55px 40px 50px 55px",
			"border-width": "1px",
			"border-style": "solid",
			"border-radius": "24px",
			"border-color": "--color-lightD2",
			"display": "flex",
			"flex-direction": "column",
			"align-items": "flex-start"
		}
	},
	"image4": {
		"kind": "Image",
		"props": {
			"src": "https://uploads.quarkly.io/612695d67f2b1f001fa06c1f/images/favourite-31.png?v=2021-08-30T20:05:47.446Z",
			"margin": "0px 0px 33px 0px"
		}
	},
	"text6": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 35px 0px",
			"color": "--dark",
			"font": "--lead",
			"lg-margin": "0px 0px 50px 0px",
			"sm-margin": "0px 0px 30px 0px",
			"flex": "1 0 auto",
			"children": <>
				Never have I been to a place that blends culture, sports, and food so seamlessly. The ambiance, the music, and especially the themed nights are unparalleled. Kudos to the team for creating such a gem!{"\n\n"}
			</>
		}
	},
	"box9": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"margin": "0px 17px 0px 0px",
			"align-items": "flex-start",
			"flex-direction": "column"
		}
	},
	"image5": {
		"kind": "Image",
		"props": {
			"width": "43px",
			"height": "43px",
			"src": "https://uploads.quarkly.io/612695d67f2b1f001fa06c1f/images/image4.png?v=2021-08-25T19:47:08.343Z",
			"border-radius": "50зч",
			"margin": "0px 15px 12px 0px"
		}
	},
	"box10": {
		"kind": "Box",
		"props": {}
	},
	"text7": {
		"kind": "Text",
		"props": {
			"color": "--dark",
			"font": "normal 600 16px/1.5 --fontFamily-googleSourceSansPro",
			"margin": "0px 0px 2px 0px",
			"children": <>
				Elena V.{"\n\n"}
			</>
		}
	}
};

const Testimonials = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
		<Box {...override("box")}>
			<Text {...override("text")} />
			<Text {...override("text1")} />
		</Box>
		<Box {...override("box1")}>
			<Box {...override("box2")}>
				<Image {...override("image")} />
				<Text {...override("text2")} />
				<Box {...override("box3")}>
					<Image {...override("image1")} />
					<Box {...override("box4")}>
						<Text {...override("text3")} />
					</Box>
				</Box>
			</Box>
			<Box {...override("box5")}>
				<Image {...override("image2")} />
				<Text {...override("text4")} />
				<Box {...override("box6")}>
					<Image {...override("image3")} />
					<Box {...override("box7")}>
						<Text {...override("text5")} />
					</Box>
				</Box>
			</Box>
			<Box {...override("box8")}>
				<Image {...override("image4")} />
				<Text {...override("text6")} />
				<Box {...override("box9")}>
					<Image {...override("image5")} />
					<Box {...override("box10")}>
						<Text {...override("text7")} />
					</Box>
				</Box>
			</Box>
		</Box>
		{children}
	</Section>;
};

Object.assign(Testimonials, { ...Section,
	defaultProps,
	overrides
});
export default Testimonials;