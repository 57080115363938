import React from "react";
import { useOverrides, Override } from "@quarkly/components";
import { Text, Hr, Image, Box, Button, Section } from "@quarkly/widgets";
const defaultProps = {
	"padding": "80px 0 90px 0",
	"quarkly-title": "menu"
};
const overrides = {
	"text": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 20px 0px",
			"font": "normal 500 56px/1.2 --fontFamily-sans",
			"text-align": "center",
			"lg-width": "70%",
			"md-width": "100%",
			"color": "--darkL1",
			"children": <>
				What's Brewing?{"\n\n"}
			</>
		}
	},
	"text1": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 20px 0px",
			"text-align": "center",
			"font": "normal 400 22px/1.5 --fontFamily-sansHelvetica",
			"color": "--darkL1",
			"padding": "0px 280px 0px 280px",
			"lg-padding": "0px 0 0px 0",
			"lg-margin": "0px 0px 50px 0px",
			"children": <>
				From age-old recipes to modern-day brews, our beers span the spectrum of German brewing excellence. Paired with our sports-inspired menu, you're in for a treat!{"\n\n"}
			</>
		}
	},
	"hr": {
		"kind": "Hr",
		"props": {
			"min-height": "10px",
			"margin": "0px 0px 50px 0px",
			"border-color": "--color-darkL1",
			"width": "40px",
			"border-width": "2px 0 0 0"
		}
	},
	"box": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"display": "grid",
			"grid-template-columns": "repeat(3, 1fr)",
			"grid-template-rows": "auto",
			"grid-gap": "0 35px",
			"md-grid-template-columns": "1fr",
			"md-grid-gap": "40px 0",
			"margin": "0px 0px 20px 0px",
			"md-margin": "0px 0px 50px 0px"
		}
	},
	"box1": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"display": "flex",
			"flex-direction": "column",
			"align-items": "flex-start"
		}
	},
	"box2": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"margin": "0px 0px 30px 0px"
		}
	},
	"image": {
		"kind": "Image",
		"props": {
			"src": "https://uploads.quarkly.io/652eb29ddbe26f0020fcdf99/images/menu_4.jpg?v=2023-10-18T07:11:07.899Z",
			"display": "block",
			"max-width": "100%",
			"margin": "0px 0px 25px 0px",
			"object-fit": "cover",
			"sm-height": "220px",
			"height": "600px",
			"lg-height": "400px",
			"md-width": "100%",
			"md-height": "450px"
		}
	},
	"box3": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"display": "flex",
			"flex-direction": "column",
			"align-items": "flex-start"
		}
	},
	"box4": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"margin": "0px 0px 30px 0px"
		}
	},
	"image1": {
		"kind": "Image",
		"props": {
			"src": "https://uploads.quarkly.io/652eb29ddbe26f0020fcdf99/images/menu_6.jpg?v=2023-10-18T07:11:07.754Z",
			"display": "block",
			"max-width": "100%",
			"margin": "0px 0px 25px 0px",
			"height": "300px",
			"width": "100%",
			"object-fit": "cover",
			"sm-height": "220px",
			"lg-height": "200px",
			"md-height": "350px"
		}
	},
	"box5": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px"
		}
	},
	"image2": {
		"kind": "Image",
		"props": {
			"src": "https://uploads.quarkly.io/652eb29ddbe26f0020fcdf99/images/menu_8.jpg?v=2023-10-18T07:11:07.732Z",
			"display": "block",
			"max-width": "100%",
			"margin": "0px 0px 25px 0px",
			"height": "300px",
			"width": "100%",
			"object-fit": "cover",
			"sm-height": "220px",
			"lg-height": "200px",
			"md-height": "350px"
		}
	},
	"box6": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"display": "flex",
			"flex-direction": "column",
			"align-items": "flex-start"
		}
	},
	"box7": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px"
		}
	},
	"image3": {
		"kind": "Image",
		"props": {
			"src": "https://uploads.quarkly.io/652eb29ddbe26f0020fcdf99/images/menu_9.jpg?v=2023-10-18T07:11:07.802Z",
			"display": "block",
			"max-width": "100%",
			"margin": "0px 0px 25px 0px",
			"object-fit": "cover",
			"sm-height": "220px",
			"height": "600px",
			"lg-height": "400px",
			"md-width": "100%",
			"md-height": "450px"
		}
	},
	"box8": {
		"kind": "Box",
		"props": {
			"min-width": "100px"
		}
	},
	"button": {
		"kind": "Button",
		"props": {
			"background": "--color-orange",
			"font": "--lead",
			"type": "link",
			"text-decoration-line": "initial",
			"href": "/contact",
			"border-radius": "8px"
		}
	}
};

const FoodImages = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<Override slot="SectionContent" align-items="center" />
		<Text {...override("text")} />
		<Text {...override("text1")} />
		<Hr {...override("hr")} />
		<Box {...override("box")}>
			<Box {...override("box1")}>
				<Box {...override("box2")}>
					<Image {...override("image")} />
				</Box>
			</Box>
			<Box {...override("box3")}>
				<Box {...override("box4")}>
					<Image {...override("image1")} />
				</Box>
				<Box {...override("box5")}>
					<Image {...override("image2")} />
				</Box>
			</Box>
			<Box {...override("box6")}>
				<Box {...override("box7")}>
					<Image {...override("image3")} />
				</Box>
			</Box>
		</Box>
		<Box {...override("box8")}>
			<Button {...override("button")}>
				Contact us
			</Button>
		</Box>
		{children}
	</Section>;
};

Object.assign(FoodImages, { ...Section,
	defaultProps,
	overrides
});
export default FoodImages;